import type { ILayoutRecord } from './DataObject.Layout.ts';
import { userSession, localStorageHelper }  from 'o365-modules';

/** Helper class for managing layouts in local storage */
export default class LayoutStore {
    private _storageKey: string;
    private layouts: ILocalStoreLayout = {};

    private get _isLockedKey() { return `${this._storageKey}_locked`; } 

    constructor(pKey: string) {
        this._storageKey = pKey;
        if (userSession.personId == null) { return; } 

        // Clear locking mechanism left overs from local store
        localStorageHelper.removeItem(this._isLockedKey);

        const layout = localStorageHelper.getItem(this._storageKey);
        if (layout) {
            const layouts = JSON.parse(layout);
            this.layouts = layouts[userSession.personId] ?? {};
            if (this.layouts) {
                // Clear any leftover layouts from previous concept
                this.layouts = {
                    setByUser: this.layouts.setByUser,
                    changes: this.layouts.changes,
                    defaultLayout: this.layouts.defaultLayout,
                };
            }
        } else {
            this.layouts = {};
        }
    }

    /** Attempt to get current layout from local storage */
    getCurrentLayout(): ILayoutRecord | null {
        // Check if there's a layout set by user
        if (this.layouts.setByUser) {
            return this._deepLayoutCopy(this.layouts.setByUser);
        }

        // Check if a default layout exists
        if (this.layouts.defaultLayout) {
            return this._deepLayoutCopy(this.layouts.defaultLayout);
        }

        // No layoutts in local storage found
        return null;
    }

    /** Get default layout */
    getDefaultLayout() {
        return this.layouts.defaultLayout;
    }

    /** Get layout applied by user */
    getUserSetLayout() {
        return this.layouts.setByUser;
    }

    /** Get saved layout changes */
    getStoredChanges() {
        return this.layouts.changes;
    }

    /** Get a layout record from the local storage by id */
    getLayoutById(id: number): ILayoutRecord | null {
        if (id == null || this.layouts == null) { return null; }
        switch (id) {
            case this.layouts.setByUser?.ID:
                return this._deepLayoutCopy(this.layouts.setByUser!);
            case this.layouts.defaultLayout?.ID:
                return this._deepLayoutCopy(this.layouts.defaultLayout!);
            default:
                return null;
        }
    }

    /** Get a default context layout record from storage by context id */
    getDefaultContextLayout(pContext: number) {
        return null;
        // if (pContext == null || this.layouts == null || this.layouts.context == null) { return null; }
        // const layoutRecord = this.layouts.context[pContext];
        // return this._deepLayoutCopy(layoutRecord);
    }

    storeLayoutChanges(pLayout: ILayoutRecord) {
        this.layouts.changes = pLayout;
        this._storeLayouts();
    }

    /** 
     * Store a layout record to the local storage
     * @param {ILayoutRecord} pLayout The layout record to store
     * @param {boolean} pUserSet Layout is applied by user and not system. Will store it as setByUser
     */
    storeLayout(pLayout: ILayoutRecord, pUserSet?: boolean) {
        if (userSession.personId == null) { return; }
        if (pLayout.ID == null) {
            console.error(`Can't store layout with no ID`);
            return;
        }

        if (pLayout.Updated) {
            pLayout.Updated = pLayout.Updated.split('.')[0];
        }

        if (pLayout.Default) {
            this.layouts.defaultLayout  = pLayout;
            this.deleteLayoutChanges();
        } else {
            this.layouts.setByUser = pLayout;
            this.deleteLayoutChanges();
        }

        this._storeLayouts();
    }

    /** 
     * Delete a layout record from the local storage
     * @param {ILayoutRecord} pLayout The layout record to delete
     */
    deleteLayout(pLayout: Partial<ILayoutRecord>) {

        if (pLayout.ID == this.layouts.setByUser?.ID) {
            delete this.layouts.setByUser;
        }
        if (pLayout.ID == this.layouts.defaultLayout?.ID) {
            delete this.layouts.defaultLayout;
        }
        if (pLayout.ID == this.layouts.changes?.ID) {
            delete this.layouts.changes;
        }

        this._storeLayouts();
    }

    deleteUserSetLayout() {
        if (this.layouts.setByUser) {
            delete this.layouts.setByUser;
            this._storeLayouts();
        }
    }

    deleteLayoutChanges() {
        if (this.layouts.changes) {
            delete this.layouts.changes;
            this._storeLayouts();
        }
    }

    /**
     * Check if the active layout is locked from local storage
     * @deprecated
     */
    getIsLocked(pId?: number) {
        return false;
    }

    /**
     * Set the active layout as locked/unlocked in local storage
     * @deprecated
     */
    setIsLocked(pId?: number, pIsLocked: boolean = true) {
        return;
    }

    /**
     * Remove default layout from local store
     */
    removeDefaultLayout() {
        if (this.layouts.defaultLayout == null) { return; }
        if (this.layouts.changes?.ID == this.layouts.defaultLayout.ID) {
            delete this.layouts.changes;
        }
        delete this.layouts.defaultLayout;
        this._storeLayouts();
    }

    /**
     * Remove user set layout from local store
     */
    removeUserSetLayout() {
        if (this.layouts.setByUser == null) { return; }
        if (this.layouts.changes?.ID == this.layouts.setByUser.ID) {
            delete this.layouts.changes;
        }
        delete this.layouts.setByUser;
        this._storeLayouts();
    }

    /** Update the layouts stored in local storage */
    private _storeLayouts() {
        if (userSession.personId == null) { return; }

        // Get entire layouts map
        const layoutJson = localStorageHelper.getItem(this._storageKey);
        let layoutsMap: Record<string, ILocalStoreLayout> = {};
        if (layoutJson) {
            layoutsMap = JSON.parse(layoutJson);
        }

        // Set or delete layouts for current person id
        if (Object.keys(this.layouts).length === 0) {
            delete layoutsMap[userSession.personId];
        } else {
            layoutsMap[userSession.personId] = this.layouts;
        }

        // Update the map in local storage
        if (Object.keys(layoutsMap).length === 0) {
            localStorageHelper.removeItem(this._storageKey);
        } else {
            localStorageHelper.setItem(this._storageKey, JSON.stringify(layoutsMap));
        }
    }

    /** Make a copy of the layout */
    private _deepLayoutCopy(pLayout: ILayoutRecord): ILayoutRecord;
    private _deepLayoutCopy(pLayout?: ILayoutRecord): ILayoutRecord | null {
        if (pLayout) {
            return {...pLayout};
        }
        return null;
    }
}

/** Layout strcuture kept in locale store */
interface ILocalStoreLayout {
    /** Explicitly set layout by the user */
    setByUser?: ILayoutRecord,
    /** Default layout */
    defaultLayout?: ILayoutRecord,
    /** Layout changes by the user */
    changes?: ILayoutRecord,
}